import Link from 'next/link';

export default function LatePledgeCard({
  id,
  imageUrl,
  title,
  collectionUsers,
  collectionItems,
  designerImage,
}: {
  id: number;
  imageUrl: string;
  title: string;
  collectionUsers: number;
  collectionItems: number;
  designerImage: string;
}) {
  return (
    <Link href={`/collections/${id}`} key={`collections-${id}`} passHref>
      <a key={`collections-${id}`}>
        <article>
          <img
            style={{
              width: '100%',
              maxWidth: 488,
              maxHeight: 274,
              objectFit: 'cover',
              aspectRatio: '16/9',
              borderRadius: 8,
              boxShadow: 'rgb(64 64 64 / 54%) 5px 5px 5px',
            }}
            src={imageUrl}
            alt={`Late pledge collection ${title}`}
          ></img>

          <div
            style={{
              background: '#7e0f23d9',
              color: 'white',
              position: 'absolute',
              bottom: 10,
              left: 0,
              padding: '5px 20px 0px 70px',
              transform: 'skew(-15deg)',
              boxShadow: 'rgb(64 64 64 / 54%) 5px 5px 5px',
            }}
          >
            <div style={{ transform: 'skew(15deg)', position: 'relative' }}>
              <img
                style={{
                  width: 30,
                  position: 'absolute',
                  left: -50,
                  bottom: 0,
                  borderRadius: '50%',
                  border: 'px solid white',
                }}
                src={designerImage}
                alt={`Late pledge collection ${title}`}
              ></img>

              <section>
                <section className='row' style={{ fontSize: 10 }}>
                  <div className='col-6' style={{ width: 100 }}>
                    <i className='fa-solid fa-cube'></i> {collectionItems}
                    {collectionUsers > 0 ? (
                      <>
                        <i
                          className='fa-solid fa-users'
                          style={{ marginLeft: 10 }}
                        ></i>{' '}
                        {collectionUsers}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </section>
                <h3 style={{ fontSize: 15 }}>{title}</h3>
              </section>
            </div>
          </div>
        </article>
      </a>
    </Link>
  );
}
