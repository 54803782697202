import Link from 'next/link';
import { UserBioInformationResult } from '../../../models/api/user-bio/user-bio-information';

export default function DesignerBio(props: {
	designerBios: UserBioInformationResult[];
}) {
	return props.designerBios?.length ? (
		<section className='row'>
			{props.designerBios?.map((bio: UserBioInformationResult) => (
				<>
					<section className='row'>
						<h3>Meet a Designer</h3>
					</section>
					<div
						className='col-sm-12 col-md-4'
						style={{
							position: 'relative',
							height: 300,
							overflow: 'hidden',
							borderRadius: '0.5rem',
						}}
					>
						<div
							style={{
								background: '#1bd3e7',
								width: 153,
								height: 345,
								position: 'absolute',
								bottom: -50,
								left: 0,
								transform: 'rotate(45deg)',
								borderRadius: '0.5rem',
							}}
						/>
						<div
							style={{
								background: '#761829',
								width: 250,
								height: 300,
								position: 'absolute',
								bottom: -50,
								left: -50,
								transform: 'rotate(45deg)',
								opacity: 0.96,
								borderRadius: '0.5rem',
							}}
						/>

						<img
							src={`${process.env.NEXT_PUBLIC_SPACES}/designerBio/${bio.userId}.webp`}
							style={{
								position: 'absolute',
								bottom: 0,
								left: 0,
								height: 300,
							}}
							alt={`Custom bio image of designer ${bio.designerName}`}
						/>
					</div>
					<div className='col-sm-12 col-md-8 mt-4'>
						<h3>{bio.realName}</h3>
						<p>{bio.text}</p>
						<Link href={`/user-page/${bio.designerName}`}>
							<div className='row' style={{ cursor: 'pointer' }}>
								<div className='col'>
									<img
										src={bio.designerImage}
										alt='Profile picture of King Virr Design'
										style={{
											width: 50,
											height: 50,
											borderRadius: 30,
											border: '5px solid white',
											display: 'inline-block',
										}}
									></img>
									<h4
										style={{
											display: 'inline-block',
											lineHeight: '50px',
											alignContent: 'center',
										}}
									>
										{bio.designerName}
									</h4>
								</div>

								<p
									className='col mt-2'
									style={{
										textAlign: 'end',
										fontSize: 20,
										color: '#761829',
									}}
								>
									Visit Designer <i className='fa-solid fa-chevron-right'></i>
								</p>
							</div>
						</Link>
					</div>
				</>
			))}
		</section>
	) : (
		<></>
	);
}
