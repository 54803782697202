import { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import {
	HomeData,
	HomeDataColletion,
	HomeDataDesigner,
	HomeDataDiscount,
	HomeDataNews,
	HomeDataProduct,
} from '../models/api/main-page/get-home-data';
import { UserBioInformationResult } from '../models/api/user-bio/user-bio-information';
import styles from '../styles/Home.module.css';
import IndexCollections from './components/index/collections';
import IndexProducts from './components/index/products';
import DesignerBio from './components/user/designer-bio';

export const getServerSideProps: GetServerSideProps<{
	randomProducts: HomeDataProduct[];
	featuredProducts: HomeDataProduct[];
	latestProducts: HomeDataProduct[];
	freeProducts: HomeDataProduct[];
	kickstarterColletion: HomeDataColletion[];
	patreonCollection: HomeDataColletion[];
	regularCollection: HomeDataColletion[];
	news: HomeDataNews[];
	discount: HomeDataDiscount[];
	designers: HomeDataDesigner[];
	designerBio: UserBioInformationResult[];
}> = async (res) => {
	let homeData = await HomeData.Get();
	//TODO: Improve error handling for non 200 status codes, console log error

	homeData = JSON.parse(JSON.stringify(homeData));

	return {
		props: {
			randomProducts: homeData.randomProducts,
			featuredProducts: homeData.featuredProducts,
			latestProducts: homeData.latestProducts,
			freeProducts: homeData.freeProducts,
			kickstarterColletion: homeData.kickstarterColletion,
			patreonCollection: homeData.patreonColletion,
			regularCollection: homeData.regularCollection,
			news: homeData.news,
			discount: homeData.discount,
			designers: homeData.designers,
			designerBio: homeData.designerBio,
		},
	};
};

export default function Home(
	homeData: InferGetServerSidePropsType<typeof getServerSideProps>
) {
	return (
		<div className={styles.container}>
			<Head>
				<title>3D printing miniatures for wargaming DnD and painting</title>
				<meta name='language' content='en' />
				<meta
					name='description'
					content={`A 3D printing marketplace and community for tabletop games, DnD, Wargames, and miniature painting. Find your next 3D model to print, paint, and bring to your game session.`}
				/>
				<meta
					name='keywords'
					content='Royal, War, Game, 3d printing, 3d models, 3d printable objects, 3d printer, 3d printing, 3d printing community, 3d scanner technology, scanner 3d, cad models, design 3d, 3mf files, diy, downloadable 3d models, download stl files, filament types, free 3d models, free stl files, color 3d prints, home 3d printing, laser cutting, maker community, makers, printable objects, print settings 3d printer, slicer settings, stl downloads, stl files'
				/>
				<link rel='icon' href='/favicon.ico' />
				<link rel='canonical' href='https://www.royalwargame.com/'></link>
			</Head>
			<main className={styles.main} style={{ maxWidth: 1000, margin: 'auto' }}>
				<section
					style={{
						padding: '40px 20px',
						zIndex: 2,
						fontSize: 30,
						fontFamily: 'titillium, sans-serif',
						width: '100%',
						position: 'relative',
						overflow: 'hidden',
						borderBottom: '10px solid #7e0f23',
						marginBottom: 20,
					}}
					className='row'
				>
					<div className='d-none d-lg-block col-lg-6'>
						{/* View only on larger screens */}
						<img
							src='/img/flat-designs/orc.svg'
							style={{ width: 400, position: 'absolute', top: -15, left: 40 }}
							alt='angry orc'
						/>
					</div>

					<h1
						className='col-lg-6'
						style={{
							color: '#515151',
							textAlign: 'center',
							fontSize: 30,
							fontFamily: 'titillium, sans-serif',
						}}
					>
						Find your next 3D model to print, paint and bring to your game
						session.
					</h1>
				</section>
				{/* TODO: Somthing like this.. but more visualy fun! 
				<p
					className='col-sm-12 col-md-7'
					style={{ textAlign: 'center', margin: 'auto' }}
				>
					RoyalWarGame is a community and marketplace for creators and makers
					alike! Support talented creators by purchasing their designs or
					tutorials. Learn new skills and techniques to improve your hobby. Join
					the community and share your work with others, earn new roles, compete
					in competitions, and join the fun. There are also of course free
					miniatures and tutorials for you to enjoy!
				</p>
				<div style={{ textAlign: 'center', margin: 'auto' }} className='mb-4'>
					<a href='#'>
						<button
							className={styles.mainButton + ' btn col-sm-12 col-md-6 mt-3'}
							onClick={() => signIn()}
						>
							Join the community
						</button>
					</a>
				</div> */}

				{/* Discounts */}
				{/* <div
					className={styles.perma_container}
					style={{
						height: 150,
						maxWidth: 1000,
						overflow: 'hidden',
						position: 'relative',
					}}
				>
					<div
						style={{ transform: 'rotate(5deg)', position: 'absolute', top: 50 }}
					>
						<div className={styles.permas} style={{ width: 4000 }}>
							<div>
								{homeData.randomProducts?.map((product) => (
									<img
										src={`${process.env.NEXT_PUBLIC_SPACES}/images/${product.id}/product-card-${product.mainImage}`}
										style={{
											width: 100,
											height: 100,
											display: 'inline-block',
										}}
									/>
								))}
								{homeData.randomProducts?.map((product) => (
									<img
										src={`${process.env.NEXT_PUBLIC_SPACES}/images/${product.id}/product-card-${product.mainImage}`}
										style={{
											width: 100,
											height: 100,
											display: 'inline-block',
										}}
									/>
								))}
							</div>
							<div>
								{homeData.randomProducts?.map((product) => (
									<img
										src={`${process.env.NEXT_PUBLIC_SPACES}/images/${product.id}/product-card-${product.mainImage}`}
										style={{
											width: 100,
											height: 100,
											display: 'inline-block',
										}}
									/>
								))}
								{homeData.randomProducts?.map((product) => (
									<img
										src={`${process.env.NEXT_PUBLIC_SPACES}/images/${product.id}/product-card-${product.mainImage}`}
										style={{
											width: 100,
											height: 100,
											display: 'inline-block',
										}}
									/>
								))}
							</div>
						</div>

						<div className={styles.permas} style={{ width: 4000 }}>
							<div>
								{homeData.randomProducts?.map((product) => (
									<img
										src={`${process.env.NEXT_PUBLIC_SPACES}/images/${product.id}/product-card-${product.mainImage}`}
										style={{
											width: 100,
											height: 100,
											display: 'inline-block',
										}}
									/>
								))}
								{homeData.randomProducts?.map((product) => (
									<img
										src={`${process.env.NEXT_PUBLIC_SPACES}/images/${product.id}/product-card-${product.mainImage}`}
										style={{
											width: 100,
											height: 100,
											display: 'inline-block',
										}}
									/>
								))}
							</div>
							<div>
								{homeData.randomProducts?.map((product) => (
									<img
										src={`${process.env.NEXT_PUBLIC_SPACES}/images/${product.id}/product-card-${product.mainImage}`}
										style={{
											width: 100,
											height: 100,
											display: 'inline-block',
										}}
									/>
								))}
								{homeData.randomProducts?.map((product) => (
									<img
										src={`${process.env.NEXT_PUBLIC_SPACES}/images/${product.id}/product-card-${product.mainImage}`}
										style={{
											width: 100,
											height: 100,
											display: 'inline-block',
										}}
									/>
								))}
							</div>
						</div>

						<div className={styles.permas} style={{ width: 4000 }}>
							<div>
								{homeData.randomProducts?.map((product) => (
									<img
										src={`${process.env.NEXT_PUBLIC_SPACES}/images/${product.id}/product-card-${product.mainImage}`}
										style={{
											width: 100,
											height: 100,
										}}
									/>
								))}
							</div>
							<div>
								{homeData.randomProducts?.map((product) => (
									<img
										src={`${process.env.NEXT_PUBLIC_SPACES}/images/${product.id}/product-card-${product.mainImage}`}
										style={{
											width: 100,
											height: 100,
										}}
									/>
								))}
								{homeData.randomProducts?.map((product) => (
									<img
										src={`${process.env.NEXT_PUBLIC_SPACES}/images/${product.id}/product-card-${product.mainImage}`}
										style={{
											width: 100,
											height: 100,
										}}
									/>
								))}
							</div>
						</div>
					</div>

					<div
						style={{
							position: 'absolute',
							top: 0,
							right: 0,
							width: '100%',
							height: '100%',
							background: '#0000007a',
						}}
					>
						<h2
							style={{
								color: 'white',
								fontSize: 25,
								textAlign: 'center',
								lineHeight: '30px',
								fontFamily: 'IMFellEnglishSC, sans-serif;',
								marginTop: 10,
							}}
						>
							King Virr Design
						</h2>
						<h2
							style={{
								color: 'white',
								fontSize: 50,
								textAlign: 'center',
								lineHeight: '50px',
								fontFamily: 'IMFellEnglishSC, sans-serif;',
							}}
						>
							SALE 30%
						</h2>
						<h3
							style={{
								color: 'white',
								fontSize: 30,
								textAlign: 'center',
								lineHeight: '30px',
								fontFamily: 'IMFellEnglishSC, sans-serif;',
							}}
						>
							- See All Products -
						</h3>
					</div>
				</div> */}

				{homeData?.news.length > 0 ? (
					<section className='col pb-4 mt-4' style={{ position: 'relative' }}>
						<Link
							href={`/homeData.news/${homeData.news[0].id}/${homeData.news[0].title}`}
							key={`homeData.news-${homeData.news[0].id}-${homeData.news[0].title}`}
							passHref
						>
							<a
								key={`homeData.news-${homeData.news[0].id}`}
								style={{ textDecoration: 'none' }}
							>
								<article className='row'>
									<img
										className='col-md-6'
										style={{
											maxWidth: 488,
											maxHeight: 274,
											objectFit: 'cover',
										}}
										src={homeData.news[0].ImageUrl}
										alt={`homeData.news image for ${homeData.news[0].title}`}
									></img>
									<section
										className='col-md-6'
										style={{ position: 'relative' }}
									>
										<h3 style={{ color: '#212529' }}>
											{decodeURIComponent(homeData.news[0].title)}
										</h3>
										<p style={{ color: '#212529' }}>
											{homeData.news[0].shortDescription}
										</p>

										<div
											className={styles.mainButton + ' pt-1 pb-1'}
											style={{
												position: 'absolute',
												bottom: 0,
												right: 0,
											}}
										>
											Read More
										</div>
									</section>
								</article>
							</a>
						</Link>
					</section>
				) : (
					<></>
				)}

				<IndexCollections
					regularCollection={homeData.regularCollection}
					latePledgeKickstarter={homeData.kickstarterColletion}
					patreonCollection={homeData.patreonCollection}
				/>

				<div className={styles.perma_container}>
					<div className={styles.permas}>
						{homeData.designers?.map((designer) => (
							<Link href={`/user-page/${designer.desingerName}`}>
								<div
									style={{
										padding: 10,
										textAlign: 'center',
										width: 100,
										cursor: 'pointer',
									}}
								>
									<img
										src={designer.image}
										style={{
											borderRadius: '50%',
											width: 50,
											height: 50,
										}}
										alt={`profile picture of ${designer.desingerName}`}
									/>
									<p style={{ fontSize: 14 }}>{designer.desingerName}</p>
								</div>
							</Link>
						))}

						{homeData.designers?.map((designer) => (
							<Link href={`/user-page/${designer.desingerName}`}>
								<div
									style={{
										padding: 10,
										textAlign: 'center',
										width: 100,
										cursor: 'pointer',
									}}
								>
									<img
										src={designer.image}
										style={{
											borderRadius: '50%',
											width: 50,
											height: 50,
										}}
										alt={`profile picture of ${designer.desingerName}`}
									/>
									<p style={{ fontSize: 14 }}>{designer.desingerName}</p>
								</div>
							</Link>
						))}
					</div>
				</div>

				<section className='row mb-5 justify-content-center'>
					<div className='col-md-12' style={{ padding: 10 }}>
						<a
							href='https://discord.gg/BwM4AREyFe'
							target='_blank'
							rel='noopener noreferrer'
						>
							<div
								style={{
									height: 60,
									padding: 20,
									background: '#333333',
									borderRadius: '0.5rem',
									overflow: 'hidden',
									position: 'relative',
									cursor: 'pointer',
								}}
							>
								<p
									style={{
										position: 'absolute',
										left: 60,
										top: 6,
										color: 'white',
										fontSize: 30,
										fontFamily: 'IMFellEnglishSC, sans-serif',
										lineHeight: '45px',
										opacity: 0.8,
									}}
								>
									Join our Discord server
								</p>
								<i
									className='fa-brands fa-discord'
									style={{
										position: 'absolute',
										right: 40,
										top: -20,
										color: 'white',
										fontSize: 80,
										transform: 'rotate(-25deg)',
										opacity: 0.1,
									}}
								></i>
							</div>
						</a>
					</div>
				</section>

				<IndexProducts
					title={'STL Files'}
					icon={'fa-regular fa-note-sticky'}
					featuredProducts={homeData.featuredProducts}
					latestProducts={homeData.latestProducts}
					popularProducts={[]}
					randomProducts={homeData.randomProducts}
					freeProducts={homeData.freeProducts}
				/>

				<DesignerBio designerBios={homeData.designerBio} />
			</main>
		</div>
	);
}
