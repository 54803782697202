import { useState } from 'react';
import { HomeDataColletion } from '../../../models/api/main-page/get-home-data';
import styles from '../../../styles/Home.module.css';
import LatePledgeCard from '../products/late-pledge-card';

export default function IndexCollections(props: any) {
	const [collectionToView, setCollectionToView] =
		useState<string>('collection');
	return (
		<>
			<section className='row mb-5'>
				<div>
					<h2 style={{ fontSize: 25, marginBottom: 0 }}>
						Collections{' '}
						<i
							className='fa-solid fa-cube'
							style={{
								transform: 'rotate(-45deg)',
								fontSize: 40,
								opacity: 0.2,
							}}
						></i>
					</h2>
					<p>Awsome Collections from our Designers</p>
				</div>

				<div>
					<button
						className={styles.mainButton + ` btn col-sm-4 col-md-2 pt-1 pb-1`}
						style={{
							background: 'transparent',
							color: collectionToView != 'collection' ? 'gray' : '#7e0f23',
							boxShadow:
								collectionToView == 'collection'
									? 'rgb(212, 212, 212) 5px 5px 5px'
									: 'none',
							position: 'relative',
							overflow: 'hidden',
						}}
						hidden={props.regularCollection?.length == 0}
						onClick={() => setCollectionToView('collection')}
					>
						Collection
						<i
							className='fa-solid fa-cube'
							style={{
								color: collectionToView != 'collection' ? 'gray' : '#7e0f23',
								transform: 'rotate(-45deg)',
								fontSize: 40,
								opacity: 0.2,
								position: 'absolute',
								right: 0,
								top: 0,
							}}
						></i>
					</button>
					<button
						className={styles.mainButton + ` btn col-sm-4 col-md-2 pt-1 pb-1`}
						style={{
							background: 'transparent',
							color: collectionToView != 'kickstarter' ? 'gray' : '#7e0f23',
							boxShadow:
								collectionToView == 'kickstarter'
									? 'rgb(212, 212, 212) 5px 5px 5px'
									: 'none',
							position: 'relative',
							overflow: 'hidden',
						}}
						hidden={props.latePledgeKickstarter?.length == 0}
						onClick={() => setCollectionToView('kickstarter')}
					>
						Kickstarter
						<i
							className='fa-brands fa-kickstarter-k'
							style={{
								color: collectionToView != 'kickstarter' ? 'gray' : '#7e0f23',
								transform: 'rotate(-45deg)',
								fontSize: 40,
								opacity: 0.2,
								position: 'absolute',
								right: 0,
								top: 0,
							}}
						></i>
					</button>
					<button
						className={styles.mainButton + ` btn col-sm-4 col-md-2 pt-1 pb-1`}
						style={{
							background: collectionToView != 'patreon' ? 'gray' : '#7e0f23',
							position: 'relative',
							overflow: 'hidden',
						}}
						hidden={props.patreonCollection?.length == 0}
						onClick={() => setCollectionToView('patreon')}
					>
						Patreon
						<i
							className='fa-brands fa-patreon'
							style={{
								transform: 'rotate(-45deg)',
								fontSize: 40,
								opacity: 0.2,
								position: 'absolute',
								right: 0,
								top: 0,
							}}
						></i>
					</button>
				</div>

				<section className='row' style={{ position: 'relative' }}>
					{props.regularCollection?.length > 0 &&
					collectionToView == 'collection' ? (
						<>
							<p className='mt-3'>
								Find whole series of your favorite designs at one place.{' '}
							</p>
							{props.regularCollection.map(
								(collection: HomeDataColletion, index: number) => (
									<section
										className={`col-md-6 ${
											index > 0 ? ' d-none d-md-block' : ''
										}`}
										style={{ position: 'relative' }}
									>
										<LatePledgeCard
											id={collection.id}
											title={collection.title}
											imageUrl={collection.imageUrl}
											collectionUsers={collection.collectionUsers}
											collectionItems={collection.collectionItems}
											designerImage={collection.designerImage}
										></LatePledgeCard>
									</section>
								)
							)}
						</>
					) : (
						<></>
					)}

					{props.latePledgeKickstarter?.length > 0 &&
					collectionToView == 'kickstarter' ? (
						<>
							<p className='mt-3'>
								Here you can catch up on the crowdfunding campaigns you have
								missed lately!
							</p>
							{props.latePledgeKickstarter.map(
								(collection: HomeDataColletion, index: number) => (
									<section
										className={`col-md-6 ${
											index > 0 ? ' d-none d-md-block' : ''
										}`}
										style={{ position: 'relative' }}
									>
										<LatePledgeCard
											id={collection.id}
											title={collection.title}
											imageUrl={collection.imageUrl}
											collectionUsers={collection.collectionUsers}
											collectionItems={collection.collectionItems}
											designerImage={collection.designerImage}
										></LatePledgeCard>
									</section>
								)
							)}
						</>
					) : (
						<></>
					)}

					{props.patreonCollection?.length > 0 &&
					collectionToView == 'patreon' ? (
						<>
							<p className='mt-3'>
								Latest Patreon releases from our awsome designers
							</p>
							{props.patreonCollection.map(
								(collection: HomeDataColletion, index: number) => (
									<section
										className={`col-md-6 ${
											index > 0 ? ' d-none d-md-block' : ''
										}`}
										style={{ position: 'relative' }}
									>
										<LatePledgeCard
											id={collection.id}
											title={collection.title}
											imageUrl={collection.imageUrl}
											collectionUsers={collection.collectionUsers}
											collectionItems={collection.collectionItems}
											designerImage={collection.designerImage}
										></LatePledgeCard>
									</section>
								)
							)}
						</>
					) : (
						<></>
					)}
				</section>
			</section>
		</>
	);
}
